import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import React, { useState ,useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalNotifation, setModalUser, setSliderBar } from "../../redux/accction/acction"
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { setIsLogin } from '../../redux/accction/acction';
import { useNavigate } from 'react-router-dom';
const SlideBar = forwardRef((props, ref) => {
  useEffect(() => {
    console.log(ref.current); 
  }, [ref]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = Cookies.get('token');
  const statusSliderBar = useSelector((state) => state.reducers.slidebarStatus);
  const isLogin = useSelector((state) => state.reducers.isLogin);
  const updateModalUser = () => {
    if (token !== null) {
      dispatch(setModalUser(true));
      dispatch(setSliderBar(false))
    }
  };
  const logout = () => {
    Cookies.remove('token');
    dispatch(setIsLogin(false))
    toast.success('Đăng xuất thành công');
    setTimeout(() => {
      navigate('/home');
    }, 800);
  }
  const getActiveClass = (path) => location.pathname === path ? 'active bg-transparent' : '';
  const colorActive = (path) => location.pathname === path ? '#B11922' : '#ffffff';
  const closeSlideBar =() =>{
    dispatch(setSliderBar(false))
  }
  return (
    <>
      <Sidebar ref={ref} className={`${statusSliderBar === false ? "d-none" : ''} d-lg-block slide-bar bg-primary-custom`}>
        <div className='d-flex flex-column height-100' >
          <Menu
            className='height-100 activce'
            menuItemStyles={{
              button: {
                [`active`]: {
                  backgroundColor: '#13395e',
                  color: '#b6c8d9',
                },
              },
            }}
          >
            <MenuItem component={<Link to="/home" />} onClick={closeSlideBar} className={`text-center ${getActiveClass('/home')}`}>
              <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#B11922"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 16V14.0003M10 14.0003V12M10 14.0003L12 14.0005M10 14.0003L8 14M21 12V11.2C21 10.0799 21 9.51984 20.782 9.09202C20.5903 8.71569 20.2843 8.40973 19.908 8.21799C19.4802 8 18.9201 8 17.8 8H3M21 12V16M21 12H19C17.8954 12 17 12.8954 17 14C17 15.1046 17.8954 16 19 16H21M21 16V16.8C21 17.9201 21 18.4802 20.782 18.908C20.5903 19.2843 20.2843 19.5903 19.908 19.782C19.4802 20 18.9201 20 17.8 20H6.2C5.0799 20 4.51984 20 4.09202 19.782C3.71569 19.5903 3.40973 19.2843 3.21799 18.908C3 18.4802 3 17.9201 3 16.8V8M18 8V7.2C18 6.0799 18 5.51984 17.782 5.09202C17.5903 4.71569 17.2843 4.40973 16.908 4.21799C16.4802 4 15.9201 4 14.8 4H6.2C5.07989 4 4.51984 4 4.09202 4.21799C3.71569 4.40973 3.40973 4.71569 3.21799 5.09202C3 5.51984 3 6.0799 3 7.2V8" stroke={colorActive('/home')} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              <p>Giao dịch</p>
            </MenuItem>
            <MenuItem component={<Link to="/user/balance"  onClick={closeSlideBar}  className={`text-center ${getActiveClass('/user/balance')}`} />}>
              <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 16V14.0003M10 14.0003V12M10 14.0003L12 14.0005M10 14.0003L8 14M21 12V11.2C21 10.0799 21 9.51984 20.782 9.09202C20.5903 8.71569 20.2843 8.40973 19.908 8.21799C19.4802 8 18.9201 8 17.8 8H3M21 12V16M21 12H19C17.8954 12 17 12.8954 17 14C17 15.1046 17.8954 16 19 16H21M21 16V16.8C21 17.9201 21 18.4802 20.782 18.908C20.5903 19.2843 20.2843 19.5903 19.908 19.782C19.4802 20 18.9201 20 17.8 20H6.2C5.0799 20 4.51984 20 4.09202 19.782C3.71569 19.5903 3.40973 19.2843 3.21799 18.908C3 18.4802 3 17.9201 3 16.8V8M18 8V7.2C18 6.0799 18 5.51984 17.782 5.09202C17.5903 4.71569 17.2843 4.40973 16.908 4.21799C16.4802 4 15.9201 4 14.8 4H6.2C5.07989 4 4.51984 4 4.09202 4.21799C3.71569 4.40973 3.40973 4.71569 3.21799 5.09202C3 5.51984 3 6.0799 3 7.2V8" stroke={colorActive('/user/balance')} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              <p>Ví</p>
            </MenuItem>
            <MenuItem component={<Link to="/user/trade/history"/>}  onClick={closeSlideBar} className={`text-center ${getActiveClass('/user/trade/history')}`}>
              <i class="fa-solid fa-gear"></i>
                <p>Bảng điều</p>
                <span>khiển</span>
              </MenuItem>
            <MenuItem component={<Link to="/excavator"/>}  onClick={closeSlideBar} className={`text-center ${getActiveClass('/excavator')}`}>
                <i className="fa-solid fa-money-bill"></i>
                <p>Máy đào</p>
              </MenuItem>
              <li className="ps-menuitem-root css-ac4ouy"  onClick={closeSlideBar}>
                <a className="ps-menu-button text-center color-white text-center d-flex flex-column" href="https://t.me/JPXVND" target="_blank"
                   rel="noopener noreferrer">
                  <i className="fa-regular fa-comment"></i>
                  <p>CSKH</p>
                </a>
              </li>

              {/* <MenuItem className='text-center' onClick={() => dispatch(setModalNotifation(true))}>
              <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              <p>Lệnh</p>
            </MenuItem> */}
              {/* <MenuItem className='d-block d-lg-none text-center mt-auto' >
             <i class="fa-solid fa-gear "></i>
              <p> Cài đặt</p>
            </MenuItem> */}
              <MenuItem className='d-block d-lg-none text-center mt-auto'  onClick={updateModalUser}>
                <i class="fa-sharp-duotone fa-solid fa-user "></i>
                <p>Hồ sơ</p>
              </MenuItem>
              <MenuItem className='text-center mt-auto' onClick={logout}>
                <i class="fa-solid fa-right-from-bracket"></i>
                <p>Đăng xuất</p>
              </MenuItem>

            </Menu>
          </div>
        </Sidebar>
      </>
  )
});

export default SlideBar
